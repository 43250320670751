.filter-button {
  margin-right: 5px;
  .surf-icon-button-text {
    display: none;
  }
}

.filters-wrapper {
  display: flex;
  flex-direction: column;
  position: relative;

  .filters {
    display: flex;
    align-items: center;

    > div {
      margin: 0 5px;

      &:last-child {
        margin: 0 !important;
      }
    }

    .filter-checkbox {
      margin: 0 12px;
      height: 33px;
      display: flex;
      align-items: center;

      > label {
        line-height: 33px;
        height: 33px;
        margin-left: 6px;
        font-size: 12px;
      }
    }

    .option {
      label {
        font-size: 12px;
      }
    }
  }

  fieldset {
    .option {
      min-height: 24px;
      position: relative;
      line-height: 0px;

      input {
        display: none;
      }

      label {

      }
    }
  }
}

