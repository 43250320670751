@import "../../sass/theme/fonts";
@import "../../sass/theme/mixins";

.fullscreen-loading-indicator {
    display: flex;
    flex: 1 1 auto;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 999999;
    background-color: rgba(#F8F8F8, 0.8);

    .fullscreen-loading-indicator-background {
        @include rounded-background-pointy-upper-left();
        display: flex;
        align-items: center;
        flex-direction: column;
        background: rgba(#899194, 0.6);
        padding: 41px 200px;

        @include loading-indicator-spinner();

        h3 {
            padding-top: 40px;
            color: white;
        }
    }
}

.loading-indicator {
    display: flex;
    flex: 1 1 auto;
    justify-content: center;
    align-items: center;
    @include loading-indicator-spinner();
}