@import "../../../sass/theme/mixins";

$inactiveMonthColor: #E5E5E5;
$activeMonthColor: #F8F8F8;

.SingleDatePickerInput.SingleDatePickerInput_1.SingleDatePickerInput__withBorder {
    @include rounded-background-pointy-upper-left($background-color);
    border: 1px solid $input-border-color;

    .DateInput.DateInput_1 {
        @include rounded-background-pointy-upper-left($background-color);

        .DateInput_input.DateInput_input_1 {
            @include open-sans;
            @include rounded-background-pointy-upper-left($background-color);
            font-size: 12px;
            font-style: normal;
            height: 50px;
            padding-left: 0;
        }

        .DateInput_input.DateInput_input_1.DateInput_input__focused.DateInput_input__focused_2 {
            @include rounded-background-pointy-upper-left($background-color);
        }
    }
}

.surf-calendar-day-wrapper {
    @include open-sans-bold;
    font-size: 10px;
    display: flex;
    background: white;
    height: 100%;
    padding: 2px;
    color: black;

    .surf-calendar-day-content {
        flex: 1 1 auto;
        background-color: orange;
        display: flex;
        justify-content: center;
        align-items: center;

        @include rounded-background-pointy-upper-left($activeMonthColor, 5px);

        &:hover {
            @include rounded-background-pointy-upper-left($majorelle, 5px);
            color: white
        }
    }
}

//Month header styling
.CalendarMonth_caption.CalendarMonth_caption_1 {
    @include nunito-bold;
    font-size: 14px;
    color: black;
}

//Next and Previous button positioning
.DayPickerNavigation.DayPickerNavigation_1.DayPickerNavigation__horizontal.DayPickerNavigation__horizontal_2 {

    .DayPickerNavigation_button__horizontal_2:nth-child(1) {
        left: 22px;
    }

    .DayPickerNavigation_button__horizontal_2:nth-child(2) {
        right: 22px;
    }

    .DayPickerNavigation_button.DayPickerNavigation_button_1.DayPickerNavigation_button__horizontal.DayPickerNavigation_button__horizontal_2 {
        position: absolute;
        top: 18px;
        line-height: .78;
        border-radius: 3px;
        padding: 6px 9px;
    }
}

.DayPicker_weekHeaders {

    .DayPicker_weekHeader {
        @include open-sans-bold;
        font-size: 10px;
        color: black;
        text-transform: uppercase;

        .DayPicker_weekHeader_ul {
            margin: -5px 0;
        }
    }
}

//General background state
.CalendarDay.CalendarDay_1.CalendarDay__default.CalendarDay__default_2 {
    border: none;
    background: none;

    &:hover {
        border: none;
    }

    //Days that fall outside of the current active month
    &.CalendarDay__outside {
        .surf-calendar-day-wrapper .surf-calendar-day-content {
            @include rounded-background-pointy-upper-left($inactiveMonthColor, 5px);

            &:hover {
                @include rounded-background-pointy-upper-left($majorelle, 5px);
                color: white
            }
        }
    }
}

//Selected day state
.CalendarDay__selected {
    &.CalendarDay__selected_3,
    &.CalendarDay__selected_4 {
        .surf-calendar-day-wrapper {
            color: white;

            .surf-calendar-day-content {
                @include rounded-background-pointy-upper-left($majorelle, 5px);
            }
        }
    }
}

.SingleDatePickerInput__withBorder {
    border: 1px solid orange;
}

.single-date-picker-wrapper {
    max-height: 52px;
    min-width: 196px;

    .SingleDatePickerInput {
        max-height: 52px;
        min-width: 196px;
    }
}