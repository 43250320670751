
.swal2-container.repoitem-container {

    .swal2-popup.repoitem-popup {
        padding: 32px 17px 25px 35px;
        width: 45em;
        min-height: 300px;
        justify-content: start;

        .swal2-content {
            padding-right: 16px !important;
        }

        .repoitem-popup-content {
            text-align: left;

            .repoitem-popup-form-divider {
                opacity: 0.2;
            }

            .close-button-container {
                position: absolute;
                right: 10px;
                top: 4px;
                padding: 7px;
                cursor: pointer;
            }

            > .loading-indicator {
                position: absolute;
                height: 100%;
                top: 0;
                transform: translateX(-50%);
                left: 50%;
            }

            &:has(.save-button-wrapper) {
                padding-bottom: 36px !important;
            }

            .save-button-wrapper {
                display: flex;
                justify-content: flex-end;
                position: absolute;
                bottom: 0;
                left: 0;
                background: white;
                width: 100%;
                padding: 14px 35px 12px 35px;
            }
        }
    }
}
