@import "../../../sass/theme/constants";
@import "../../../sass/theme/fonts";
@import "../../../sass/theme/mixins";
@import "../../../sass/utils/mixins";
@import "../../../sass/mediaquery/mediaquery";

.react-table-filter-row {
    margin-bottom: 20px;

    .form-field {
        position: relative;

        input[type=text] {
            width: 400px;
            padding-left: 35px;
        }

        svg {
            color: $text-color;
            font-size: 14px;
            position: absolute;
            left: 10px;
            top: 50%;
            transform: translateY(-50%);
        }
    }

    .checkbox {
        width: 100%;
        margin-top: auto;
        margin-bottom: auto;
        margin-left: 20px;

        .option:first-child {
            margin-top: 0;
        }
    }
}