@import "../sass/theme/constants";
@import "../sass/theme/fonts";
@import "../sass/theme/mixins";

.swal2-container.verification-container {

    .swal2-popup.verification-popup {
        padding: 25px 35px;
        width: 45em;
        min-height: 150px;

        .verification-content {
            text-align: left;

            .close-button-container {
                position: absolute;
                right: 13px;
                top: 4px;
                padding: 7px;
                cursor: pointer;
            }


            .verification-title {
                @include nunito-extra-bold;
                font-size: 25px;
            }


            .verification-subtitle {
                @include nunito-bold;
                font-size: 20px;
                margin-top: 10px;
                max-height: 400px;
                min-height: 100px;
            }
        }

        .smallSubtitle {
            .verification-subtitle {
                font-size: 14px !important;
                font-weight: normal;
                overflow-y: scroll;
                padding-right: 10px;
            }
        }

        .button-wrapper {
            margin-top: 20px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;

            &.center-button {
                justify-content: center;
            }
        }
    }
}
