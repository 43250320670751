@import "../../../sass/theme/constants";
@import "../../../sass/theme/fonts";
@import "../../../sass/theme/mixins";
@import "../../../sass/utils/mixins";
@import "../../../resources/fontawesome/css/all.css";

.tasks {
  @include open-sans-bold();

  .header {
    display: flex;
    flex-direction: row;
    align-items: center;
    align-content: center;
    font-size: 10px;
    color: white !important;
    padding-bottom: 7px;

    .status-icon {
      padding: 0;
    }

    .date {
      @include open-sans-bold();
    }

    .title {
      font-size: 10px;
    }
  }

  .cell {
    display: flex;
    flex-direction: row;
    align-items: center;
    align-content: center;
  }

  .task-list {
    @include rounded-background-pointy-upper-left();
    display: flex;
    flex-direction: column;
    color: $text-color;
    font-size: 12px;
    padding-top: 10px;
    padding-bottom: 5px;

    .loading-indicator {
      margin-top: 40px;
    }

    .loading-subtitle {
      flex-grow: 1;
      text-align: center;
      margin-top: 30px;
      margin-bottom: 40px;
    }

    .task-row {
      display: flex;
      flex-direction: row;
      align-items: center;
      height: 50px;
    }

    .table-row-divider {
      margin-left: 12px;
      margin-right: 12px;
      height: 1px;
      background-color: #E5E5E5;
    }
  }

  .checkbox {
    margin-left: 20px;
  }

  .document-icon {
    font-size: 14px;
    margin-left: 20px;
  }

  .title {
    @include max-number-of-lines(1, 16px);
    margin-left: 10px;
    font-size: 12px;
  }

  .date {
    margin-left: 46px;
    @include open-sans();
  }

  .copy-icon {
    font-size: 14px;
  }

  .delete-icon {
    font-size: 14px;
    margin-left: 20px;
  }

  .edit-button {
    margin-left: 20px;
  }

  .title-cell {
    width: 40%;
  }

  .status-cell {
    width: 15%;
  }

  .author-cell {
    width: 15%;
  }

  .date-cell {
    width: 15%;
  }

  .action-cell {
    width: 15%;
    padding-right: 15px;
  }
}