.empty-state-container {
    background: white;
    border-radius: 2px 15px 15px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 40px;

    .empty-list-title {
        font-family: 'Nunito', sans-serif;
        font-weight: 800;
        font-size: 25px;
        margin-top: 30px;
    }
}