@import "../../sass/theme/constants";
@import "../../sass/theme/fonts";
@import "../../sass/theme/mixins";
@import "../../sass/utils/mixins";

.profile-banner {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 14px;
}

.profile-banner-image {
    width: 33px;
    height: 33px;
    border-radius: 4px;
    margin-right: 0;

    &.placeholder {
        color: $text-color-active;
        padding: 4px;
    }

    .icon {
        width: 100%;
        height: 100%;
    }
}