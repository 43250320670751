@import "../sass/theme/constants";
@import "../sass/theme/fonts";
@import "../sass/theme/mixins";
@import "../sass/utils/mixins";

.swal2-container.export-container {

    .swal2-popup.export-popup {
        padding: 25px 35px;
        width: 45em;
        min-height: 300px;

        .export-content {
            text-align: left;

            .close-button-container {
                position: absolute;
                right: 13px;
                top: 4px;
                padding: 7px;
                cursor: pointer;
            }

            #export-form {
                padding-top: 40px;
            }
        }

        #form-export {
            margin-top: 20px;

            .radio-group-container {
                flex-direction: column;

                .field-label-radio-group {
                    @include form-field-label-style;
                    text-transform: uppercase;
                }

                .export-radio-group {
                    display: flex;
                    align-items: center;
                    padding-top: 5px;
                    padding-bottom: 10px;

                    .option {
                        margin-top: 0;
                        margin-right: 15px;
                    }
                }
            }

            .save-button-wrapper {
                display: flex;
                justify-content: flex-end;
                margin-top: 20px;

                .call-to-action {
                    cursor: pointer;
                }
            }
        }


        .form-column {


            &.hidden {
                display: none;
            }
        }
    }
}
