@import "grid_mixins";
@import "z-span";

.row {
	//Desktop layout
	@include grid-row-values-desktop;

	&.with-margin {
		//Mobile & Tablet layout
		@include grid-row-values-mobile-tablet;
	}
}
