@import "../../../sass/theme/constants";
@import "../../../sass/theme/fonts";
@import "../../../sass/theme/mixins";
@import "../../../sass/utils/mixins";

.surf-icon-button {
    display: flex;

    .surf-icon-button-container {
        @include rounded-background-pointy-upper-right(#2D364F);
        @include nunito-bold;
        display: flex;
        align-items: center;
        color: white;
        cursor: pointer;
        font-size: 14px;
        padding: 12px 15px;

        &.primary {
            background-color: $space-cadet;

            &:hover,
            &:active {
                background-color: $space-cadet-light;
                color: #F8F8F8;
            }
        }

        &.call-to-action {
            background-color: $majorelle;

            &:hover,
            &:active {
                background-color: $majorelle-light;
                color: white;
            }
        }

        .surf-icon-button-icon {
            width: 14px;
            height: 14px;
        }

        .surf-icon-button-text {
            padding-left: 15px;
        }
    }
}