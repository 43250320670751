@import "../../../sass/theme/constants";
@import "../../../sass/theme/fonts";
@import "../../../sass/utils/mixins";
@import "../../../sass/theme/mixins";

.tree-multiselect {
    background-color: white !important;
    flex-grow: 1;

    .plus-button {
        justify-content: start;

        &.with-top-margin {
            margin-top: 30px;
        }
    }

    .field-label {
        margin-top: 17px;
    }

    .sortable-container {
        flex-grow: 1;
        margin-top: 5px;
    }

    &.disabled {
        .surf-sortable-row {
            border: 1px solid $background-color-dark;
            background-color: transparent;
        }
    }
}

.surf-sortable-row {
    flex-grow: 1;
    background-color: $background-color-dark;
    border-radius: 5px;
    height: 50px;
    padding-left: 15px;
    padding-right: 15px;

    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 5px;

    .order-icon {
        color: $vivid-sky;
        cursor: grab;
    }

    .document-icon {
        margin-left: 33px;
    }

    .edit-icon {
        margin-left: 7px;
        font-size: 12px;
        cursor: pointer;
    }

    .delete-icon {
        margin-left: 22px;
        font-size: 12px;
        cursor: pointer;
    }
}