@import "../sass/theme/constants";
@import "../sass/theme/fonts";
@import "../sass/theme/mixins";
@import "../sass/utils/mixins";
@import "../sass/grid/grid_mixins";
@import "../sass/grid/z-span";
@import "../resources/fontawesome/css/all.css";

.surf-dropdown {
    min-width: 200px;
}

.reports-title-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 50px;

    > div {
        display: flex;
        align-items: center;
    }
}

.table-row-actions {
    display: flex;
    justify-content: flex-end;
    column-gap: 16px;

    button {
        background: transparent;
    }
}

.search-count {
    display: flex;
    margin-left: 16px;
    font-size: 12px;
    color: white;
    width: 34px;
    height: 34px;
    align-items: center;
    justify-content: center;
    @include open-sans-bold;
    @include rounded-background-pointy-upper-left($space-cadet, 8px);
}