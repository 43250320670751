@import '../../sass/theme/constants';
@import '../../sass/theme/fonts';
@import '../../sass/theme/mixins';

.horizontal-tab-list {
    display: flex;
    flex-direction: row;

    .horizontal-tab {
        display: flex;
        flex-direction: column;
        align-items: center;
        cursor: pointer;

        &.with-margin {
            margin-left: 20px;
        }

        .tab-title {
            @include nunito-bold;
            font-size: 12px;
            line-height: 16px;
            display: flex;
            align-items: center;
            text-align: center;
            color: $text-color;
            margin-bottom: 10px;

            &.active {
                color: $text-color-active;
            }
        }

        .tab-underline {
            width: 125px;
            height: 7px;
            background-color: #E5E5E5;
            border-radius: 7px;

            &.active {
                background-color: $text-color-active;
            }
        }
    }
}