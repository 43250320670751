@import "../sass/theme/constants";
@import "../sass/theme/fonts";
@import "../sass/theme/mixins";
@import "../sass/utils/mixins";
@import "../sass/mediaquery/mediaquery";

#trashcan {
    background: $page-background-gradient;

    .trashcan-page-content {
        .title-row {
            display: flex;
            align-items: center;

            h1 {
                margin-right: 20px
            }

            .search-count {
                display: flex;
                margin-left: 16px;
                font-size: 12px;
                color: white;
                width: 34px;
                height: 34px;
                align-items: center;
                justify-content: center;
                @include open-sans-bold;
                @include rounded-background-pointy-upper-left($space-cadet, 8px);
            }
        }

        .search-input-container {
            margin-top: 20px;
            flex: 1 1 auto;
            margin-right: 0;
        }

        .trashcan-disclaimer {
            background: $flame-light;
            max-width: 550px;
            padding: 20px 20px;
            border-radius: 2px $default-radius $default-radius;
            margin-top: 20px;
        }

        .trashcan-results-list {
            @include rounded-background-pointy-upper-left();
            margin-top: 25px;
            background-color: white;
            padding-left: 15px;
            padding-right: 15px;

            .search-result-row:last-child .row-content {
                border-bottom: none;
            }
        }
    }
}