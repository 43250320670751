@import "../../../sass/theme/constants";
@import "../../../sass/theme/fonts";
@import "../../../sass/theme/mixins";
@import "../../../sass/utils/mixins";

.flex {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.custom-tooltip {
  display: flex;
  align-items: center;
  background: white;
  min-width: 50px;
  max-height: 30px;
  text-align: center;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.label {
  margin: 0 auto;
  font-size: 12px;
  font-weight: 700;
}

.triangle {
  width: 10px;
  height: 10px;
  transform: rotate(45deg);
  background: red;
  position: relative;
  bottom: 5px;
}